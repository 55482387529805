import { COLOURS } from '@core-ui/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FileText } from '@phosphor-icons/react';
import React from 'react';

const ImageNoPreview = () => {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Box width={50} height={50}>
        <FileText color={COLOURS.WHITE} />
      </Box>
    </Grid>
  );
};

export default ImageNoPreview;
