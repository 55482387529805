import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ApproveUser } from '@/generated';
import React from 'react';
import useStyles from '../styles';

interface IProps {
  users: ApproveUser[];
}

const UserList = (props: IProps) => {
  const { users } = props;
  const styles = useStyles();

  const userList = users.map((user, index) => (
    <Grid item key={index}>
      <Typography sx={styles.userTooltipText}>
        {user.first_name} {user.last_name}
      </Typography>
    </Grid>
  ));

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      maxHeight={200}
      width="auto"
      maxWidth={200}
      flexWrap="nowrap"
      overflow="hidden scroll"
      padding={0}
      sx={styles.userListContainer}
    >
      {userList}
    </Grid>
  );
};

export default UserList;
