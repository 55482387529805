import { COLOURS } from '@core-ui/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'components/Intl';
import useStyles from '../styles';

export const classNameForOverlay = 'imageOverlay';
const PAGINATION_STEP = 2;

interface IProps {
  pages: any[];
  index: number;
  setIndex: Dispatch<SetStateAction<number>>;
  getImage: (item: any) => string;
}

const ImageItemOverlay = (props: IProps) => {
  const { pages, index, setIndex, getImage } = props;
  const styles = useStyles();

  const paginationStep = pages.length >= 15 ? PAGINATION_STEP * 2 : PAGINATION_STEP;
  const lengthWithoutStep = pages.length - 1 - paginationStep;
  const paginationStart = index <= paginationStep ? 0 : index - paginationStep;
  const paginationEnd = index >= lengthWithoutStep ? pages.length : index + 1 + paginationStep;
  const paginationPages = pages.slice(paginationStart, paginationEnd);
  const beforeStub = Array(index <= paginationStep ? paginationStep - index : 0).fill(null);
  const afterStub = Array(index >= lengthWithoutStep ? index - lengthWithoutStep : 0).fill(null);

  const onClickPage = (idx: number) => () => {
    setIndex(idx);
  };

  const renderPage = (page: any, idx: number) => {
    const src = getImage(page);
    const idxPage = index <= paginationStep ? idx : idx + index - paginationStep;
    const idxActive = index <= paginationStep ? index : paginationStep;

    const pagePaginationActive =
      idx === idxActive
        ? {
            width: 40,
            opacity: 1,
          }
        : undefined;

    return (
      <Box
        key={src}
        onClick={onClickPage(idxPage)}
        sx={{
          ...styles.pagePagination,
          ...pagePaginationActive,
          backgroundImage: `url(${src})`,
        }}
      />
    );
  };

  const renderPageStub = (_page: any, idx: number) => {
    return (
      <Box
        key={idx}
        sx={{
          ...styles.pagePagination,
          cursor: 'initial',
        }}
      />
    );
  };

  return (
    <Grid
      container
      className={classNameForOverlay}
      height={80}
      position="absolute"
      zIndex={1}
      bottom={0}
      padding="24px 16px 8px"
      alignItems="flex-end"
      justifyContent="center"
      sx={styles.pagesWrapperOverlay}
    >
      <Typography color={COLOURS.WHITE} position="absolute" left="16px" bottom="8px">
        <FormattedMessage id="imageCarousel.photo_count" values={{ total: pages.length, current: index + 1 }} />
      </Typography>

      <Grid container flexWrap="nowrap" width="auto" gap={2}>
        {beforeStub.map(renderPageStub)}

        {paginationPages.map(renderPage)}

        {afterStub.map(renderPageStub)}
      </Grid>
    </Grid>
  );
};

export default ImageItemOverlay;
