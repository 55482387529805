import { getCurrencyCodeById } from '@/dictionary/selector';
import { useAppSelector } from '@/hooks/useAppSelector';
import { useMemo } from 'react';

interface ICurrencyAmount {
  amount?: number;
  currencyId?: number;
}

/*
 * Deprecated component, use getCurrencyCodeById selector instead.
 * This component will be deleted during the Request section rework.
 */
const CurrencyAmount = (props: ICurrencyAmount) => {
  const { amount, currencyId } = props;
  const memoizedSelector = useMemo(() => getCurrencyCodeById, []);

  const currencyIntl = useAppSelector((state) => memoizedSelector(state, { id: currencyId! }));

  if (!amount || !currencyId || !currencyIntl) {
    return null;
  }

  return <>{currencyIntl(amount)}</>;
};

export default CurrencyAmount;
