import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { colorsByStatus, textByStatus } from '@/components/ApprovalFlowStatus/utils';
import { Ball, StatusBallEnum } from '@/generated';
import { Users } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import useStyles from '../styles';
import UserList from './UserList';

interface IProps {
  level: number;
  ball: Ball;
}

const ContentLevel = (props: IProps) => {
  const { level, ball } = props;
  const styles = useStyles();

  const levelText = textByStatus[ball.status];
  const levelColors = colorsByStatus[ball.status];

  return (
    <Grid item container gap={2} wrap="nowrap">
      <Grid item>
        <Box sx={styles.statusIndicator} bgcolor={levelColors.indicator} />
      </Grid>

      <Grid item container direction="column" gap={1} width="auto" wrap="nowrap">
        <Grid item>
          <Typography>
            <FormattedMessage id="label.level" values={{ level }} />
          </Typography>
        </Grid>

        <Grid item container wrap="nowrap">
          {ball.status === StatusBallEnum.AWAITING ? (
            <Typography color={levelColors.text} variant="body2" sx={styles.userTooltipText}>
              <FormattedMessage id={levelText} />
            </Typography>
          ) : (
            <Tooltip
              title={
                <Box overflow="hidden" maxWidth={200}>
                  <Typography sx={styles.userTooltipText}>
                    {ball.made_by_user?.first_name} {ball.made_by_user?.last_name}
                  </Typography>
                </Box>
              }
              placement="top"
            >
              <Typography color={levelColors.text} variant="body2" sx={styles.userTooltipText}>
                <FormattedMessage id={levelText} /> <FormattedMessage id="label.by" /> {ball.made_by_user?.first_name}{' '}
                {ball.made_by_user?.last_name}
              </Typography>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <Grid item container width="auto" marginLeft="auto" alignItems="center">
        <Grid container width="auto" flexWrap="nowrap">
          <Tooltip title={<UserList users={ball.users} />} placement="right">
            <Grid item container gap={1} wrap="nowrap">
              <SvgIcon>
                <Users />
              </SvgIcon>

              <Grid item container alignItems="center">
                <Typography variant="body2" sx={styles.userCount}>
                  {ball.users.length}
                </Typography>
              </Grid>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContentLevel;
