import { chatTokenName, getToken } from '@/api/token-store';
import { IRouteParams } from '@/app/consts/routes';
import WebSocketClient from '@/components/MattermostSocket';
import { getChatsPostsList, getChatsUsersList } from '@/pages/chats/ChatsChannel/actions';
import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

enum EventEnum {
  POSTED = 'posted',
}

interface SocketEvent {
  event: EventEnum;
  broadcast: { channel_id: string };
}

interface IProps {
  children: ReactNode;
}

const SocketLayout = (props: IProps) => {
  const { chatId } = useParams<IRouteParams>();

  const dispatch = useDispatch();

  useEffect(() => {
    const fn = async () => {
      try {
        await WebSocketClient.initialize(getToken(chatTokenName)!, {});
      } finally {
        WebSocketClient.setEventCallback(eventHandler);
      }
    };
    fn();
  }, []);

  const onPosted = (e: SocketEvent) => {
    if (e.broadcast.channel_id === chatId) {
      dispatch(getChatsPostsList({ chatId }));
      dispatch(getChatsUsersList({ chatId }));
    }
  };

  const eventHandler = (e: SocketEvent) => {
    switch (e.event) {
      case EventEnum.POSTED:
        return onPosted(e);
      default:
        return null;
    }
  };

  return props.children as JSX.Element;
};

export default SocketLayout;
