import Grid, { GridProps } from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';
import useStyles from './styles';

interface IProps extends GridProps {
  direction?: 'column' | 'row';
  wrap?: 'wrap' | 'nowrap';
  sx?: SxProps<Theme>;
}

const ScreenLayout = (props: IProps) => {
  const { children, direction = 'column', wrap = 'nowrap', sx, flex = 1, ...rest } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      direction={direction}
      overflow="hidden"
      flex={flex}
      wrap={wrap}
      className={classes.root}
      sx={sx}
      {...rest}
    >
      {children}
    </Grid>
  );
};

export default ScreenLayout;
