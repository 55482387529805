import { asMutable } from '@core-ui/immutable';
import { IOption, Nullable } from '@core-ui/types';
import { currencyIntlSelector } from '@/app/selectors';
import { AmountFormatter, CurrencyCodeGetterById, CurrencyFormatterById, CurrencyIntlFunc } from '@/app/types/common';
import { captureCurrency } from '@/app/utils/app';
import { POPULAR_CURRENCIES } from '@/dictionary/consts';
import { ICurrencyDict } from '@/dictionary/reducer';
import { EmployeeSchema } from '@/generated';
import capitalize from 'lodash-es/capitalize';
import isNil from 'lodash-es/isNil';
import { createSelector, Selector } from 'reselect';
import State from '../app/types/state';

const getProps = (_: State, props: { id: number | string }) => props.id;
const getEmployeeIdProps = (_: State, props: { employeeId: Nullable<number> }) => props.employeeId;

export const currencyDictSelector = (state: State) => state.dictionary.currencies;
export const currencyDictMapSelector = (state: State) => state.dictionary.currencyMap;
export const notificationDictSelector = (state: State) => state.dictionary.notifications;
export const boatDictSelector = (state: State) => state.dictionary.boats;
export const employeeDictSelector = (state: State) => state.dictionary.employees;
export const departmentDictSelector = (state: State) => state.dictionary.departments;
export const costCenterDictSelector = (state: State) => state.dictionary.costCenters;
export const vendorDictSelector = (state: State) => state.dictionary.vendors;
export const costArticleDictSelector = (state: State) => state.dictionary.costArticles;
export const clientCostArticleDictSelector = (state: State) => state.dictionary.clientCostArticles;
export const requestDictSelector = (state: State) => state.dictionary.requests;

// formatted amount with currency code
export const getAmountFormatterWithCurrencyCodeById = createSelector<
  [Selector<State, Record<number, ICurrencyDict> | undefined>, Selector<State, CurrencyIntlFunc>],
  CurrencyFormatterById
>(
  [currencyDictMapSelector, currencyIntlSelector],
  (map, currencyIntl) => (currencyId?: Nullable<number>, value?: Nullable<number>) => {
    if (isNil(value)) {
      return '';
    }

    if (!map || !currencyId) {
      return currencyIntl().format(value);
    }

    const option = map[currencyId];

    if (!option) {
      // send error to sentry if we have no such currency in the map
      captureCurrency(currencyId, map);

      return currencyIntl().format(value);
    }

    return currencyIntl(map[currencyId].code).format(value);
  }
);

export const getCurrencyCodeById = createSelector<
  [Selector<State, Record<number, ICurrencyDict> | undefined>],
  CurrencyCodeGetterById
>([currencyDictMapSelector], (map) => (currencyId?: Nullable<number>) => {
  if (!map || !currencyId) {
    return '';
  }

  const option = map[currencyId];

  if (!option) {
    captureCurrency(currencyId, map);

    return '';
  }

  return option.code;
});

// formatted amount without currency code
export const getAmountFormatter = createSelector<[Selector<State, CurrencyIntlFunc>], AmountFormatter>(
  [currencyIntlSelector],
  (currencyIntl) => (value?: Nullable<number>) => {
    if (isNil(value)) {
      return '';
    }

    return currencyIntl().format(value);
  }
);

// formatted amount only integer values without currency code
export const getIntAmountFormatter = createSelector<[Selector<State, CurrencyIntlFunc>], AmountFormatter>(
  [currencyIntlSelector],
  (currencyIntl) => (value?: Nullable<number>) => {
    if (isNil(value)) {
      return '';
    }

    return currencyIntl(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
  }
);

export const getCurrencyDictOptions = createSelector([currencyDictSelector], (currencies) => {
  // removing USD (101) and EUR (103) from the list to add them to the top later
  const options = currencies.reduce<IOption[]>((acc, item) => {
    if (item.id === 101 || item.id === 103) {
      return acc;
    }

    return [...acc, { value: item.id, label: item.code }];
  }, []);

  const optionsWithPopularCurrencies = [...POPULAR_CURRENCIES, ...options];

  return asMutable(optionsWithPopularCurrencies);
});

export const getBoatsDictOptions = createSelector(
  [boatDictSelector],
  (boatsDict) =>
    asMutable(
      boatsDict.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    ) as IOption[]
);

export const getDepartmentById = createSelector([departmentDictSelector, getProps], (departments, departmentId) =>
  departments.find((d) => {
    return d.id === +departmentId;
  })
);

export const getDepartmentsDictOptions = createSelector(
  [departmentDictSelector],
  (departments) =>
    asMutable(
      departments.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    ) as IOption[]
);

export const getEmployeeByIdSelector = createSelector([employeeDictSelector, getEmployeeIdProps], (employee, id) =>
  employee.find((e) => e.id === id)
);

export const getUploadedByOptions = createSelector([employeeDictSelector], (uploadedByOptions) =>
  Object.values(uploadedByOptions).map((option) => ({
    label: `${option.first_name} ${option.last_name}`,
    value: option.id,
  }))
);

export const getCostCentersDictOptions = createSelector(
  [costCenterDictSelector],
  (costCenters) =>
    asMutable(
      costCenters.map((item) => ({
        value: item.id,
        label: capitalize(item.name.replace(/_/g, ' ')),
      }))
    ) as IOption[]
);

export const getVendorsDictOptions = createSelector(
  [vendorDictSelector],
  (vendors) =>
    asMutable(
      vendors.map((item) => ({
        value: item.id,
        label: capitalize(item.name.replace(/_/g, ' ')),
      }))
    ) as IOption[]
);

export const getCostArticlesDictOptions = createSelector(
  [costArticleDictSelector],
  (costArticles) =>
    asMutable(
      costArticles.map((item) => ({
        value: item.id,
        label: capitalize(item.name.replace(/_/g, ' ')),
      }))
    ) as IOption[]
);

export const getClientCostArticlesDictOptions = createSelector(
  [clientCostArticleDictSelector],
  (clientCostArticles) =>
    asMutable(
      clientCostArticles.map((item) => ({
        value: item.code,
        label: `${item.code} - ${capitalize(item.name.replace(/_/g, ' '))}`,
      }))
    ) as IOption[]
);

export const getRequestsDictOptions = createSelector(
  [requestDictSelector],
  (requests) =>
    asMutable(
      requests.map((item) => ({
        value: item.id,
        label: capitalize(item.name.replace(/_/g, ' ')),
      }))
    ) as IOption[]
);
