import NodataLayout, { INoDataLayoutProps } from '@core-ui/no_data';
import State from '@/app/types/state';
import { useAppSelector } from '@/hooks/useAppSelector';
import React from 'react';
import { Selector } from 'reselect';

export type NoDataContainerSelector = Selector<
  State,
  {
    hasData: boolean;
    loaded: boolean;
    loading: boolean;
    error: Error | undefined;
  }
>;

interface IProps extends Omit<INoDataLayoutProps, 'loaded' | 'loading' | 'hasData'> {
  selector: NoDataContainerSelector;
  equalityFn?: <T>(left: T, right: T) => boolean;

  [prop: string]: any;
}

const NoDataContainer = (props: IProps) => {
  const { selector, equalityFn, children, ...rest } = props;
  const { loaded, loading, hasData, error } = useAppSelector(selector, equalityFn);

  return (
    <NodataLayout {...rest} loaded={loaded} loading={loading} hasData={hasData} error={error} loaderMode="circle">
      {children}
    </NodataLayout>
  );
};

export default NoDataContainer;
