import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';
import { ArrowLeft } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import useStyles from './styles';

interface IProps {
  direction: 'right' | 'left';
  isActive: boolean;
  onClick: () => void;
}

const HideButton = (props: IProps) => {
  const { direction, onClick, isActive } = props;
  const classes = useStyles();

  return (
    <Tooltip title={<FormattedMessage id={isActive ? 'tooltip.show' : 'tooltip.hide'} />} placement="bottom" arrow>
      <button
        type="button"
        className={cn(classes.root, classes[direction], {
          [classes.leftActive]: direction === 'left' && isActive,
          [classes.rightActive]: direction === 'right' && isActive,
        })}
        onClick={onClick}
      >
        <SvgIcon>
          <ArrowLeft />
        </SvgIcon>
      </button>
    </Tooltip>
  );
};

export default HideButton;
