import { Nullable } from '@core-ui/types';
import { getCurrencyCodeById } from '@/dictionary/selector';
import { useAppSelector } from '@/hooks/useAppSelector';
import { useMemo } from 'react';

interface IProps {
  currencyId?: Nullable<string | number>;
}

/*
 * Deprecated component, use getCurrencyCodeById selector instead.
 * This component will be deleted during the Request section rework.
 */
const CurrencyCode = (props: IProps) => {
  const { currencyId } = props;

  const memoizedSelector = useMemo(() => getCurrencyCodeById, []);

  const currencyCode = useAppSelector((state) => memoizedSelector(state));

  if (!currencyId || !currencyCode) {
    return null;
  }

  return <>{currencyCode(Number(currencyId)).replace('NaN', '').trim()}</>;
};

export default CurrencyCode;
