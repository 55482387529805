import Button from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import { ArrowLeft, ArrowRight } from '@phosphor-icons/react';
import React, { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'components/Intl';
import { MIN_ROTATE, MIN_ZOOM } from '../ImagesCarousel';
import useStyles from '../styles';
import ImageItem from './ImageItem';
import ImageItemOverlay from './ImageItemOverlay';
import ImageNoPreview from './ImageNoPreview';

interface IProps {
  pages: any[];
  fileIndex: number;
  setFileIndex: Dispatch<SetStateAction<number>>;
  rotate?: number;
  zoom?: number;
  setZoom?: (value: number) => void;
  getImage: (item: any) => string;
  checkImage: (item: any) => boolean;
  showControls?: boolean;
}

const Carousel = (props: IProps) => {
  const {
    pages,
    fileIndex,
    setFileIndex,
    rotate = MIN_ROTATE,
    zoom = MIN_ZOOM,
    setZoom,
    getImage,
    checkImage,
    showControls,
  } = props;

  const styles = useStyles({ showControls });
  const intl = useIntl();

  const showImage = pages.length > 0 && pages[fileIndex] && checkImage(pages[fileIndex]);

  const goToNext = () => {
    setFileIndex(fileIndex === pages.length - 1 ? 0 : fileIndex + 1);
  };

  const goToPrev = () => {
    setFileIndex(fileIndex === 0 ? pages.length - 1 : fileIndex - 1);
  };

  return (
    <Grid container className="PagesCarousel" flex="1" sx={styles.pagesWrapper}>
      <Button
        variant="text"
        onClick={goToPrev}
        aria-label={intl.formatMessage({ id: 'aria.label.open_previous_image' })}
        icon={
          <SvgIcon htmlColor={COLOURS.WHITE}>
            <ArrowLeft />
          </SvgIcon>
        }
        sx={styles.buttonPrev}
      />
      {showImage ? (
        <>
          <ImageItem src={getImage(pages[fileIndex])} rotate={rotate} zoom={zoom} setZoom={setZoom} />
          <ImageItemOverlay pages={pages} index={fileIndex} setIndex={setFileIndex} getImage={getImage} />
        </>
      ) : (
        <ImageNoPreview />
      )}
      <Button
        variant="text"
        onClick={goToNext}
        aria-label={intl.formatMessage({ id: 'aria.label.open_next_image' })}
        icon={
          <SvgIcon htmlColor={COLOURS.WHITE}>
            <ArrowRight />
          </SvgIcon>
        }
        sx={styles.buttonNext}
      />
    </Grid>
  );
};

export default Carousel;
