import { COLOURS } from '@core-ui/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import { APPROVAL_TOOLTIP_MIN_WIDTH } from '@/components/ApprovalFlowStatus/consts';
import ContentLevel from '@/components/ApprovalFlowStatus/TooltipContent/ContentLevel';
import { Ball } from '@/generated';
import React from 'react';

interface IProps {
  approvalBalls?: Ball[];
}

const dividerStyles: SxProps<Theme> = {
  backgroundColor: COLOURS.Coconut.Solid[700],
};

const TooltipContent = (props: IProps) => {
  const { approvalBalls } = props;

  return (
    <Grid item container direction="column" gap={1} paddingY={1} minWidth={APPROVAL_TOOLTIP_MIN_WIDTH}>
      {approvalBalls?.map((ball, index) => (
        <>
          <ContentLevel level={index + 1} ball={ball} key={index} />

          {index < approvalBalls.length - 1 && <Divider sx={dividerStyles} />}
        </>
      ))}
    </Grid>
  );
};

export default TooltipContent;
