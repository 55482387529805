import {
  convertValueToUTCDate,
  dateToDayEndUTC,
  dateToDayStartUTC,
  dateToUTC,
  format as formatCoreUI,
  getTimestampInMs as getTimestampInMsCoreUI,
  localizeDate,
  timezone,
} from '@core-ui/dates';
import {
  addMonths as addMonthsFNS,
  eachMonthOfInterval as eachMonthOfIntervalFNS,
  endOfDay as endOfDayFNS,
  endOfMonth as endOfMonthFNS,
  endOfQuarter as endOfQuarterFNS,
  endOfToday as endOfTodayFNS,
  endOfYear as endOfYearFNS,
  fromUnixTime as fromUnixTimeFNS,
  getMonth as getMonthFNS,
  getQuarter as getQuarterFNS,
  getUnixTime as getUnixTimeFNS,
  getYear as getYearFNS,
  parseISO as parseISOFNS,
  startOfDay as startOfDayFNS,
  startOfMonth as startOfMonthFNS,
  startOfYear as startOfYearFNS,
  sub as subFNS,
} from 'date-fns';
import isNil from 'lodash-es/isNil';

export const getEndOfTodayInUnix = () => Math.floor(localizeDate(endOfTodayFNS(), timezone).getTime() / 1000);

export const getMonthAgoInUnix = () =>
  Math.floor(localizeDate(subFNS(endOfTodayFNS(), { months: 1 }), timezone).getTime() / 1000);

export const getStartOfCurrentYearInUnix = () =>
  dateToDayStartUTC(startOfYearFNS(dateToDayStartUTC(new Date()) * 1000));

export const getEndOfCurrentYearInUnix = () =>
  Math.floor(dateToDayEndUTC(endOfYearFNS(dateToDayEndUTC(new Date()) * 1000)));

export const getEndOfMonthFromUnixInUnix = (date?: number) => {
  if (isNil(date)) {
    return undefined;
  }

  const lastDayOfMonthDate = endOfMonthFNS(fromUnixTimeFNS(date));

  return Math.floor(localizeDate(lastDayOfMonthDate, timezone).getTime() / 1000);
};

export const getEndOfQuarterFromUnixInUnix = (date?: number) => {
  if (isNil(date)) {
    return undefined;
  }

  const lastMonthOfQuarterDate = endOfQuarterFNS(fromUnixTimeFNS(date));

  return Math.floor(localizeDate(lastMonthOfQuarterDate, timezone).getTime() / 1000);
};

export const getEndOfYearFromUnixInUnix = (date?: number) => {
  if (isNil(date)) {
    return undefined;
  }

  const lastMonthOfYearDate = endOfYearFNS(fromUnixTimeFNS(date));

  return Math.floor(localizeDate(lastMonthOfYearDate, timezone).getTime() / 1000);
};

export const getStartOfCurrentYear = () => convertValueToUTCDate(getStartOfCurrentYearInUnix());
export const getEndOfCurrentYear = () => convertValueToUTCDate(getEndOfCurrentYearInUnix());

export const formatTime = formatCoreUI;
export const getTimestampInMs = getTimestampInMsCoreUI;
export const startOfYear = startOfYearFNS;
export const startOfMonth = startOfMonthFNS;
export const startOfDay = startOfDayFNS;
export const endOfDay = endOfDayFNS;
export const endOfToday = endOfTodayFNS;
export const endOfMonth = endOfMonthFNS;
export const endOfYear = endOfYearFNS;
export const endOfQuarter = endOfQuarterFNS;
export const getUnixTime = getUnixTimeFNS;
export const fromUnixTime = fromUnixTimeFNS;
export const addMonths = addMonthsFNS;
export const sub = subFNS;
export const parseISO = parseISOFNS;
export const getMonth = getMonthFNS;
export const getYear = getYearFNS;
export const eachMonthOfInterval = eachMonthOfIntervalFNS;
export const getQuarter = getQuarterFNS;

export { dateToUTC, convertValueToUTCDate, dateToDayStartUTC, dateToDayEndUTC };
