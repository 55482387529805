import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { exhaustiveGuard } from '@/app/utils/app';
import { ApprovedBall, AwaitingBall, DeclinedBall } from '@/components/ApprovalFlowStatus/StatusBalls';
import TooltipContent from '@/components/ApprovalFlowStatus/TooltipContent';
import { DocumentSearchResult, StatusBallEnum } from '@/generated';
import React from 'react';

interface IProps {
  item: Nullable<DocumentSearchResult>;
}

// TODO: по идее этот компонент можно перенести в /pages/ledger т.к. он является общим для Леджера, но не для других модулей
const ApprovalFlowStatus = (props: IProps) => {
  const { item } = props;

  if (!item) {
    return null;
  }

  return (
    <Grid item container width="auto" alignItems="center" wrap="nowrap">
      <Tooltip title={<TooltipContent approvalBalls={item.approve_balls} />} placement="top">
        <Grid item container gap={2} wrap="nowrap">
          {item.approve_balls?.map((ball, index) => {
            switch (ball.status) {
              case StatusBallEnum.APPROVED:
                return <ApprovedBall key={index} />;
              case StatusBallEnum.DECLINED:
                return <DeclinedBall key={index} />;
              case StatusBallEnum.AWAITING:
                return <AwaitingBall key={index} />;
              default:
                exhaustiveGuard(ball.status);

                return null;
            }
          })}
        </Grid>
      </Tooltip>
    </Grid>
  );
};

export default ApprovalFlowStatus;
